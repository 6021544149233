import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/mdx.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageHeading = makeShortcode("PageHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageHeading title={props.pageContext.frontmatter.title} image="meeting" crumbs={["TechSlice", props.pageContext.frontmatter.title]} mdxType="PageHeading"></PageHeading>
    <div className="container" style={{
      marginBottom: "4rem"
    }}>
      <h1><u>{`Privacy Policy`}</u></h1>
      <h2>{`Acceptance of Terms`}</h2>
      <p>{`This Privacy Policy applies to the website: techslice.com (the “Site”) and does not govern privacy practices associated with offline activities.  These are the guidelines used by TechSlice, LLC (“COMPANY”) in protecting your privacy.  COMPANY reserves the right to modify these terms at any time and in any manner, without prior notice.`}</p>
      <p>{`COMPANY respects your privacy and is committed to protecting the information you provide us through the Site.  We do not sell or distribute user information to unaffiliated third parties, except as needed to provide services that you have requested.  We may gather Site use information and distribute this information to affiliated companies in order to serve your needs and respond to your information requests.`}</p>
      <ol>
        <li parentName="ol">
          <u>User Information</u>. During your interaction with the Site, COMPANY may request information from you.  The only information COMPANY will collect and store about you is information you decide to provide us.  If you have voluntarily submitted user information to us through an email or contact form or any other information, COMPANY will only use such information for the purpose that it was provided or as otherwise permitted by law.
        </li>
        <li parentName="ol">
          <u>Other Information</u>. COMPANY may use server logs to record a visitor’s Internet Protocol (IP) address and to collect general information about the visit to the Site, such as the time and length of the visit, and the web pages accessed during the visit.  COMPANY may use this information for Site management and performance monitoring only.  COMPANY does not make this information available to unaffiliated third parties, but may share it with affiliated companies.
        </li>
        <li parentName="ol">
          <u>Cookies</u>. COMPANY may use cookies from time to time to allow COMPANY to tailor the Site to your preferences or interests, customize promotions or marketing or identify which areas of the Site are more popular.  A cookie is a small, unique text file that a website can send to your computer hard drive when you visit that site.  COMPANY does not make any cookie information available to unaffiliated third parties.  Most web browsers can either alert you to the use of cookies or refuse to accept cookies entirely.  If you do not want COMPANY to deploy cookies in your browser, you can set your browser to reject cookies or to notify you when a website tries to put a cookie on your computer.  Rejecting cookies may affect your ability to use some of the services available on the Site.
        </li>
        <li parentName="ol">
          <u>Security</u>. To prevent unauthorized access to any user information, COMPANY has put in place commercially reasonable physical, electronic, and managerial procedures to safeguard and secure the information it collects through this Site.  However, COMPANY cannot guarantee the security of such information.
        </li>
      </ol>
      <p><em parentName="p">{`For More Information`}</em></p>
      <p>{`If you have any comments, concerns or questions regarding these terms, please contact us at (410) 891-4050.`}</p>
      <p>{`Last updated on July 5, 2021.`}</p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      